import React from 'react'
import Mini from '../components/miniSect';
import { StaticQuery, graphql, Link } from "gatsby";
import Layout from '../components/layout';
import QueryLike from '../components/stackedmini';

class Products extends React.Component{
    render(){
        return(
            <Layout nomargin>
                <div className="productsHolder">
                    <p className="productsTitle">Antiparasitic</p>
                    <div className="productsSubHolder">
                    <QueryLike subject="antiparasitic" />
                    </div>
                    
                </div>
                
            </Layout>
        );
    }
}
export default Products